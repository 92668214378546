@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.container {
  margin: 4rem auto 7rem auto;
  padding: 0 2rem;
  @media (max-width: 880px) {
    margin: 3.5rem auto 5rem auto;
  }
  .title {
    text-align: center;
    font-weight: $weight-medium;
    font-size: $font-large;
    line-height: 2.7rem;
    @media (max-width: 880px) {
      font-size: $font-medium;
      line-height: 2rem;
    }
    @media (min-width: 881px) and (max-width: 1280px) {
      font-size: $font-medium;
      line-height: 2rem;
    }
  }
  .image-section {
    @include display-flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    gap: 2.5rem;
    .img {
      width: 180px;
      padding: 0.2rem 3rem;
    }
  }
}
