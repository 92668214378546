@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.single-nav-item {
  position: relative;
  padding: 0.5rem 1rem;
  .item-label {
    @include display-flex(center, none);
    @include hover;
    color: $color-darkHigh;
    font-size: 1.1rem;
    gap: 0.5rem;
    &:hover {
      color: $color-primary;
    }
    .arrow-icon {
      transition: transform 0.2s ease;
      font-size: $font-small;
      &.rotate-180 {
        transform: rotate(180deg);
      }
    }
  }
  .dropdown {
    @include hover;
    display: flex;
    flex-direction: column;
    background-color: $color-snow;
    border-radius: $radii-small;
    gap: 0.3rem;
    padding: 0.6rem 0.2rem;
    line-height: 1.4rem;
    .dropdown-link {
      @include display-flex(center, none);
      @include hover;
      color: $color-grayMedium;
      font-size: 1.0rem;
      line-height: 1.3rem;
      padding: 0.2rem 0.1rem;
      .new {
        background-color: $color-darkHigh;
        color: $color-snow;
        border-radius: $radii-default;
        font-size: 0.675rem;
        padding: 0.2rem 0.4rem;
        margin-left: 0.8rem;
      }
      &:hover {
        color: $color-darkHigh;
      }
    }
  }
}
