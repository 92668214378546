@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.container {
  background-color: $color-primary;
  color: $color-snow;
  .container-section {
    @include container(1380px, 0 auto);
    @include display-flex(flex-start, space-between);
    border-bottom: 0.0625rem solid $color-grayMedium;
    padding: 4rem 2rem 4rem 2rem;
    @media (max-width: 884px) {
      flex-direction: column;
      padding: 3rem 2rem;
    }
    .info-section {
      .img {
        width: 18rem;
        height: 70px;
        margin-bottom: 1.2rem;
        margin-left: -9px;
        @media (max-width: 884px) {
          width: 250px;
          height: 70px;
        }
      }
      .paragraph {
        color: $color-grayMedium;
        font-size: $font-small;
        font-weight: $weight-medium;
        margin-bottom: 1.2rem;
      }
      .subtitle {
        font-size: $font-default;
        font-weight: $weight-medium;
        margin-bottom: 1rem;
        @media (min-width: 885px) and (max-width: 1224px) {
          font-size: $font-default;
        }
        @media (max-width: 884px) {
          font-size: $font-default;
        }
      }
      .icons-section {
        @include display-flex(center, flex-start);
        gap: 1.4rem;
        margin-bottom: 1.5rem;
        .icon {
          background-color: $color-grayLight;
          color: $color-primary;
          font-size: $font-medium;
          border-radius: 50%;
          padding: 0.4rem;
          width: 1.9rem;
          height: 1.9rem;
          @media (min-width: 885px) and (max-width: 1224px) {
            font-size: $font-default;
          }
          @media (max-width: 884px) {
            font-size: $font-default;
          }
        }
      }
    }
    .details-section {
      @include display-flex(flex-start, center);
      gap: 6.8rem;
      @media (min-width: 885px) and (max-width: 1224px) {
        @include display-flex(flex-start, space-between);
        padding-left: 3.5rem;
        gap: 2rem;
      }
      @media (max-width: 884px) {
        flex-direction: column;
        gap: 2rem;
      }
      .list-section {
        .title {
          font-size: $font-default;
          font-weight: $weight-medium;
          margin-bottom: 1rem;
          @media (max-width: 884px) {
            font-size: $font-default;
            margin-bottom: 0.8rem;
          }
        }
        .list-box {
          .list {
            @include hover;
            white-space: wrap;
            color: $color-grayMedium !important;
            font-size: $font-default;
            font-weight: $weight-medium;
            margin-bottom: 0.4rem;
            &:hover {
              color: $color-grayLight;
            }
            @media (min-width: 885px) and (max-width: 1224px) {
              font-size: $font-small;
              margin-bottom: 0.2rem;
            }
            @media (max-width: 884px) {
              font-size: $font-small;
              margin-bottom: 0.2rem;
            }
          }
        }
      }
    }
  }
  .container-info {
    @include container(1380px, 0 auto);
    @include display-flex(flex-start, space-between);
    padding: 0.8rem 2rem 1rem 2rem;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    .rights-reserved-section {
      white-space: normal;
      color: $color-grayMedium;
      font-size: $font-small;
      font-weight: $weight-medium;
      margin-bottom: 0.2rem;
      line-height: 1.3rem;
      .link {
        @include hover;
        &:hover {
          color: $color-snow;
        }
      }
      @media (min-width: 885px) and (max-width: 1224px) {
        font-size: $font-small;
      }
      @media (max-width: 884px) {
        font-size: $font-small;
        margin-bottom: 0.7rem;
        line-height: 1.2rem;
      }
    }
    .terms-section {
      @include display-flex;
      color: $color-grayMedium;
      font-size: $font-small;
      font-weight: $weight-medium;
      gap: 2rem;
      .link {
        @include hover;
        &:hover {
          color: $color-snow;
        }
      }
      @media (min-width: 885px) and (max-width: 1224px) {
        font-size: $font-small;
      }
      @media (max-width: 884px) {
        font-size: $font-small;
        line-height: 1.2rem;
        margin: 0.3rem 0 0.7rem 0;
      }
    }
  }
}
