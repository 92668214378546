@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.mobile-nav-container {
  @include display-flex(center, flex-end);
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999;
  left: 0;
  top: 0;
  @media (min-width: 1184px) {
    display: none;
  }
  .mobile-nav {
    background-color: $color-snow;
    color: $color-darkHigh;
    height: 100%;
    width: 100%;
    padding: 1rem;
    .close-section {
      @include display-flex(none, flex-end);
      @include hover;
      .close-icon {
        cursor: pointer;
        font-size: 2.5rem;
      }
      &:hover {
        color: $color-yellow;
      }
    }
    .nav-items {
      display: flex;
      flex-direction: column;
      font-size: $font-small;
      gap: 0.5rem;
    }
    .button-section {
      @include display-flex;
      @include hover;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      border-radius: 5px;
      padding: 0.8rem 1.4rem;
      margin-top: 1rem;
      background-color: $color-success;
      color: $color-snow;
      font-family: 'Inter', sans-serif;
      .link {
        color: inherit;
      }
      .icon {
        font-size: 1.75rem;
        margin-left: 0.5rem;
      }
      @media (min-width: 1024px) {
        display: flex;
      }
    }
    .menu-icon {
      @include hover;
      display: block;
      font-size: $font-medium;
      @media (min-width: 1184px) {
        display: none;
      }
    }
  }
}
