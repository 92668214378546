@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.container {
  background-color: $color-primary;
  color: $color-snow;
  font-family: 'Inter', sans-serif;
  .container-section {
    @include container(1380px, 0 auto);
    @include display-flex(flex-start, space-between);
    border-bottom: 0.0625rem solid $color-grayMedium;
    padding: 4rem 2rem 4rem 2rem;
    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 3rem 2rem;
    }
    .info-section {
      width: 40%;
      @media (max-width: 1024px) {
        width: 100%;
      }
      margin-right: 5rem;
      .info-title {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }
      .img {
        width: 18rem;
        height: 70px;
        margin-bottom: 1.2rem;
        margin-left: 2px;
        @media (max-width: 1024px) {
          width: 250px;
          height: 70px;
        }
      }
      .subtitle {
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        font-weight: $weight-medium;
        margin: 1rem 0;
        @media (min-width: 1025px) and (max-width: 1224px) {
          font-size: $font-default;
        }
        @media (max-width: 1024px) {
          font-size: $font-default;
        }
      }
      .icons-section {
        @include display-flex(center, flex-start);
        gap: 1.4rem;
        margin-bottom: 1.5rem;
        .icon {
          background-color: $color-grayLight;
          color: $color-primary;
          font-size: $font-medium;
          border-radius: 50%;
          padding: 0.4rem;
          width: 1.9rem;
          height: 1.9rem;
          @media (min-width: 1025px) and (max-width: 1224px) {
            font-size: $font-default;
          }
          @media (max-width: 1024px) {
            font-size: $font-default;
          }
        }
      }
    }
    .details-section {
      @include display-flex(flex-start, space-around);
      gap: 4rem;
      width: 100%;
      @media (min-width: 1025px) and (max-width: 1224px) {
        @include display-flex(flex-start, space-between);
        gap: 2rem;
    
      }
      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 2rem;
      }
      .list-section {
        .title {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          margin-bottom: 1rem;
          @media (max-width: 1024px) {
            font-size: $font-default;
            margin-bottom: 0.8rem;
          }
        }
        .list-box {
          .list {
            @include hover;
            white-space: wrap;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 1rem;
            &:hover {
              color: $color-yellow;
            }
            @media (min-width: 1025px) and (max-width: 1224px) {
              font-size: $font-small;
              margin-bottom: 0.2rem;
            }
            @media (max-width: 1024px) {
              font-size: $font-small;
              margin-bottom: 0.2rem;
            }
          }
        }
      }
    }
  }
  .container-info {
    @include container(1380px, 0 auto);
    @include display-flex(flex-start, space-between);
    padding: 0.8rem 2rem 1rem 2rem;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    .rights-reserved-section {
      white-space: normal;
      color: $color-snow;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      .link {
        @include hover;
        &:hover {
          color: $color-snow;
        }
      }
      @media (min-width: 885px) and (max-width: 1224px) {
        font-size: $font-small;
      }
      @media (max-width: 884px) {
        font-size: $font-small;
        margin-bottom: 0.7rem;
        line-height: 1.2rem;
      }
    }
    .terms-section {
      @include display-flex;
      color: $color-snow;
      font-size: 16px;
      line-height: 24px;
      line-height: normal;
      font-weight: 400;
      gap: 2rem;
      cursor: pointer;
      @media (min-width: 885px) and (max-width: 1224px) {
        font-size: $font-small;
      }
      @media (max-width: 884px) {
        font-size: $font-small;
        line-height: 1.2rem;
        margin: 0.3rem 0 0.7rem 0;
      }
    }
  }
  .info-credits {
    @include container(1380px, 0 auto);
    padding: 0rem 2rem 2rem 2rem;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 20px;
    font-weight: 400;
    text-align: justify;
  }
}
