@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.navbar {
  @include display-flex(center, space-between);
  box-shadow: rgba(0, 0, 0, 0.16) 0 0.06rem 0.25rem;
  position: fixed;
  margin: 0 auto;
  color: $color-snow;
  background-color: $color-primary;
  font-weight: $weight-default;
  padding: 1.2rem 1.7rem;
  height: 5.6rem;
  z-index: 999;
  width: 100%;
  .left-section {
    @include display-flex(center, flex-start);
    gap: 1rem;
    .logo {
      width: 200px;
      height: 50px;
    }
    .nav-items {
      display: none;
      @media (min-width: 1024px) {
        @include display-flex(center, flex-start);
        .nav-link {
          @include hover;
          position: relative;
          padding: 1rem 0.7rem;
          .link-text {
            @include display-flex(center, flex-start);
            color: $color-snow;
            font-size: $font-small;
            border-bottom: 0.0625rem solid $color-primary;
            gap: 0.4rem;
            .rotate-180 {
              @include hover(all 0.3s ease);
              transform: rotate(180deg);
            }
          }
          .dropdown {
            display: none;
            flex-direction: column;
            position: absolute;
            font-weight: $weight-medium;
            background-color: $color-snow;
            color: $color-darkHigh;
            border-radius: $radii-default;
            padding: 1.2rem 1rem;
            margin-top: 0.8rem;
            left: 2.5rem;
            top: 2.5rem;
            gap: 0.1rem;
            .dropdown-link {
              @include display-flex(center, flex-start);
              @include hover;
              color: $color-darkHigh;
              font-size: $font-small;
              padding: 0.4rem 0.6rem;
              &:hover {
                background-color: $color-secondary;
              }
              .new {
                background-color: $color-darkHigh;
                color: $color-snow;
                font-weight: $weight-medium;
                border-radius: $radii-default;
                font-size: 0.675rem;
                padding: 0.2rem 0.4rem;
                margin: 0.2rem;
              }
              .link-label {
                white-space: nowrap;
                padding: 0 0.6rem;
              }
            }
          }
          &:hover .dropdown {
            display: flex;
          }
        }
        .nav-link:hover .arrow-icon {
          transform: rotate(0deg);
        }
        .nav-link:hover .dropdown {
          display: flex;
        }
      }
    }
  }
  .right-section {
    @include display-flex;
    @include hover;
    font-size: $font-small;
    border-radius: $radii-small;
    padding: 0.6rem 1.2rem;
    .link {
      color: inherit;
    }
    @media (max-width: 1124px) {
      display: none;
    }
    &:hover {
      background-color: $color-snow;
      color: $color-darkHigh;
    }
    .icon {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
    @media (min-width: 1024px) {
      display: flex;
    }
  }
  .menu-icon {
    @include hover;
    display: block;
    font-size: $font-medium;
    @media (min-width: 1024px) {
      display: none;
    }
  }
}
