@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.faq-fgts {
  padding: 20px;
  margin: 3.5rem 0 0 0;

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    color: $color-yellow;
  }
  &__title {
    font-size: 48px;
    line-height: 56px;
    font-weight: 600;
    text-align: center;
    margin: 0.5rem 0 3rem 0;
    font-family: 'Poppins', sans-serif;
  }

  &__list {
    max-width: 1080px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 10px;
  }

  &__item {
    border-bottom: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 0;
    cursor: pointer;
    &--active {
      background-color: #f9f9f9;
    }
  }

  &__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    font-weight: 600;
    line-height: 24px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    .box {
      .number {
        color: $color-yellow;
        font-weight: 700;
        line-height: 24px;
        font-size: 16px;
        padding-right: 0.5rem;
        font-family: 'Inter', sans-serif;
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }

  &__icon {
    font-size: 34px;
    font-weight: 400;
    color: #6a6a6a;
  }

  &__answer {
    padding: 0.25rem 4rem 1rem 4rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Inter', sans-serif;
    @media (max-width: 800px) {
      padding: 0.25rem 2rem 1rem 2rem;
    }
  }
  .button-container {
    @include display-flex;
    margin-top: 3rem;
    .button-section {
      @include display-flex;
      @include hover;
      display: block;
      line-height: 20px;
      font-weight: 700;
      border-radius: 5px;
      width: 200px;
      padding: 0.8rem 1.4rem;
      font-family: 'Inter', sans-serif;
      background-color: $color-success;
      color: $color-snow;
      .link {
        color: inherit;
      }
      @media (max-width: 1124px) {
        display: none;
      }
      .icon {
        font-size: 1.75rem;
        margin-left: 0.75rem;
      }
      @media (min-width: 1024px) {
        display: flex;
      }
    }
  }
}
