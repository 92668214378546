@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('../assets/fonts/Satoshi-Variable.woff') format('woff'),
    url('../assets/fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Satoshi', sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: pre-line;
}

input,
button {
  border: 0;
  outline: 0;
  background: none;
  font-family: 'Satoshi', sans-serif;
  line-height: 1.48;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 700;
}

a {
  text-decoration: none;
  background: none;
  cursor: pointer;
  border: 0;
  outline: 0;
  color: inherit;
}

button {
  cursor: pointer;
  border: 0;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

ul,
li,
p {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
}

// CSS (fgts.module.scss ou global.css)
img {
  width: 100%;
  height: auto; // ✅ Altura proporcional para evitar cortes
  object-fit: cover; // ✅ Cobre o espaço do container sem distorcer

  @media (max-width: 624px) {
    object-fit: contain; // ✅ Em telas menores, a imagem se ajusta sem cortar
  }
}
