@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.container {
  background: $color-secondary;
  padding: 0 0.9rem;
  .box-section {
    @include container(1380px, 9.5rem auto 12.5rem auto);
    position: relative;
    .info-section {
      @include display-flex;
      flex-wrap: wrap;
      .col-lg-3 {
        flex: 0 0 20%;
        max-width: 25%;
        padding: 0 6rem;
        margin: 0 1rem;
        z-index: 90;
        @media (max-width: 992px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @media (max-width: 576px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .col-sm-6 {
        padding: 0 0.625rem;
        @media (max-width: 576px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .col-xs-12 {
        padding: 0 0.625rem;
      }
      .no-padding {
        padding: 0;
      }
    }
    .numbers-section {
      @include display-flex;
      box-shadow: rgba(100, 100, 111, 0.15) 0px 0.4rem 1.8rem 0px;
      flex-direction: column;
      text-align: center;
      background-color: $color-snow;
      border-radius: $radii-default;
      height: 11.5rem;
      margin-top: -5.75rem;
      @media (max-width: 1084px) {
        margin-top: -1.575rem;
      }
      .icon {
        color: $color-primary;
        border-radius: $radii-default;
        margin-bottom: 1rem;
      }
      .title {
        font-size: $font-default;
        font-weight: $weight-medium;
        margin-bottom: 0.4rem;
        line-height: 1.5rem;
      }
      .subtitle {
        font-size: $font-small;
        font-weight: $weight-light;
        line-height: 1.375rem;
      }
    }
    .video-section {
      @include display-flex;
      .video {
        @include display-flex;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 0.3rem 0.9rem;
        position: relative;
        width: 55vw;
        border-radius: 0.625rem;
        height: 25.5rem;
        margin-top: 5.2rem;
        margin-bottom: -9.3rem;
        @media (max-width: 993px) {
          width: 90vw;
        }
      }
    }
  }
}
