@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.mobile-nav-container {
  @include display-flex(center, flex-end);
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999;
  left: 0;
  top: 0;
  @media (min-width: 1024px) {
    display: none;
  }
  .mobile-nav {
    background-color: $color-snow;
    color: $color-darkHigh;
    height: 100%;
    width: 75%;
    padding: 1rem;
    .close-section {
      @include display-flex(none, flex-end);
      .close-icon {
        cursor: pointer;
        font-size: 2.5rem;
      }
    }
    .nav-items {
      display: flex;
      flex-direction: column;
      font-size: $font-small;
      gap: 0.5rem;
    }
    .auth-buttons {
      @include display-flex(flex-start, none);
      font-size: $font-default;
      padding: 1rem;
      .link {
        color: $color-darkHigh;
      }
      .icon {
        font-size: 2rem;
        margin-left: 0.3rem;
      }
    }
  }
}
