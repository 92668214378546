@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.container {
  @include container(1280px, 5rem auto 7.5rem auto);
  padding: 0 2rem;
  @media (max-width: 1024px) {
    @include container(1280px, 3rem auto 4.5rem auto);
  }
  .row {
    display: flex;
    &.justify-content-center {
      justify-content: center;
    }
    &.form-row {
      align-items: center;
    }
    .title-section {
      text-align: center;

      .title {
        font-size: $font-large;
        font-weight: $weight-medium;
        line-height: 2.5rem;
        margin-bottom: 3.5rem;
        @media (max-width: 1024px) {
          font-size: $font-medium;
          line-height: 1.8rem;
        }
      }
      .subtitle {
        font-size: $font-default;
        font-weight: $weight-default;
        margin-bottom: 0.3rem;
        @media (max-width: 1024px) {
          font-size: $font-default;
        }
      }
    }
  }
  .col {
    &.col-12 {
      width: 100%;
    }
    &.col-md-8 {
      @media (min-width: 768px) {
        width: 66.6667%;
      }
    }
    &.col-3 {
      width: 25%;
    }
    &.col-6 {
      width: 50%;
    }
    &.col-7 {
      width: 58.3333%;
    }
    &.col-5 {
      width: 41.6667%;
    }
  }
  .mb4 {
    margin-bottom: 1.5rem;
  }
  .mb6 {
    margin-bottom: 2.5rem;
  }
  .mb2 {
    margin-bottom: 0.5rem;
  }
  .text-center {
    text-align: center;
  }
  .img-square {
    position: relative;
    padding-bottom: 100%;
    .img-cover {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2px;
    }
  }
}
