@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.container {
  @include container(1280px, 4.4rem auto 6.2rem auto);
  padding: 0 2rem;
  @media (max-width: 800px) {
    margin: 3rem auto 4rem auto;
  }
  .title {
    text-align: center;
    font-size: $font-large;
    font-weight: $weight-medium;
    line-height: 3rem;
    margin-bottom: 3rem;
    @media (max-width: 800px) {
      text-align: left;
      font-size: $font-medium;
      margin: 0.4rem 0 2.5rem 0;
      line-height: 2.1rem;
    }
    @media (min-width: 801px) and (max-width: 1024px) {
      line-height: 2.6rem;
    }
  }
  .faq-section {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0.0625rem 0.25rem;
    background-color: $color-secondary;
    border-radius: $radii-default;
    padding: 1.2rem;
    margin-bottom: 1rem;
    @media (max-width: 800px) {
      line-height: 1.4rem;
    }
    .faq-question {
      @include hover;
      position: relative;
      font-size: $font-default;
      font-weight: $weight-default;
      margin: 0.2rem 0;
      padding-right: 4rem;
      &::after {
        content: '+';
        position: absolute;
        transition: all 0.2s ease;
        transform: translateY(-50%);
        color: $color-primary;
        font-size: 1.4rem;
        height: 1.8rem;
        width: 1.8rem;
        top: 50%;
        right: 0;
      }
    }
    &.open .faq-question::after {
      content: '-';
    }
    .faq-answer {
      overflow: hidden;
      transition: max-height 0.4s ease, opacity 0.4s ease;
      font-size: $font-default;
      max-height: 0;
      opacity: 0;
    }
    &.open .faq-answer {
      max-height: 1000px;
      opacity: 1;
      animation: expand 0.4s ease forwards;
    }
    &.closed .faq-answer {
      animation: collapse 0.4s ease forwards;
    }
  }
  .btn-section {
    @include display-flex;
    margin-top: 3rem;
  }
}
@keyframes expand {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 62.5rem;
    opacity: 1;
  }
}
@keyframes collapse {
  0% {
    max-height: 62.5rem;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}
