@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.container-box {
  background: linear-gradient(to right, #ffcc29 30%, #000040 30%);
  @media (max-width: 960px) {
    background: linear-gradient(to right, #000040 30%, #000040 30%);
  }
  .container {
    @include display-flex(center, space-between);
    @include container;
    padding: 3rem 2.5rem;
    gap: 20px;
    font-family: 'Inter', sans-serif;
    @media (min-width: 961px) and (max-width: 1280px) {
    }
    @media (max-width: 960px) {
      padding: 4rem 2.5rem;
    }
    .left {
      flex: 1;
      position: relative;
      @media (min-width: 961px) and (max-width: 1280px) {
        padding: 4rem 2.5rem;
      }
      @media (max-width: 960px) {
        display: none;
      }
      .image-wrapper {
        position: relative;
      }

      .image {
        height: 550px;
        width: 500px;
        position: relative;
        top: 60px;
        border-radius: 8px;
        @media (min-width: 1079px) and (max-width: 1280px) {
          top: 116px;
        }
        @media (min-width: 960px) and (max-width: 1078px) {
          top: 140px;
        }
      }

      .badge {
        @include hover;
        background: $color-snow;
        position: absolute;
        padding: 1rem 1.5rem;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border-radius: 5px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        &:hover {
          transform: translateY(-5px);
          transition: transform 0.3s ease-out;
        }
      }
    }

    .right {
      flex: 1;
      color: $color-snow;
      text-align: left;
      @media (max-width: 960px) {
        padding: 0 2rem;
      }
      .text-section {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon-title {
          border: 1px solid $color-yellow;
          color: $color-snow;
          border-radius: 50%;
          padding: 1rem;
          font-size: 24px;
          margin-right: 1.5rem;
          @media (max-width: 624px) {
            display: none;
          }
        }

        .title {
          font-size: 40px;
          line-height: 56px;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }

      .list {
        list-style: none;
        margin: 0;
        padding: 0;
        counter-reset: step;
        @media (max-width: 960px) {
        }
        li {
          font-size: 18px;
          line-height: 20px;
          font-weight: 600;
          padding-left: 50px;
          position: relative;
          margin: 1.5rem 0;

          &:before {
            content: counter(step, decimal-leading-zero) '.';
            counter-increment: step;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 18px;
            color: $color-yellow;
            font-weight: bold;
          }
        }
      }

      .highlight {
        font-size: 40px;
        line-height: 56px;
        color: $color-yellow;
        font-weight: 600;
        margin: 1rem 0 1.5rem 0;
      }

      .button-section {
        @include display-flex;
        @include hover;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        border-radius: 5px;
        width: 200px;
        padding: 0.8rem 1.4rem;
        font-family: 'Inter', sans-serif;
        background-color: $color-success;
        .link {
          color: inherit;
        }
        .icon {
          font-size: 1.75rem;
          margin-left: 0.75rem;
        }
        @media (min-width: 1024px) {
          display: flex;
        }
      }
      .menu-icon {
        @include hover;
        display: block;
        font-size: $font-medium;
        @media (min-width: 1024px) {
          display: none;
        }
      }

      .contact {
        margin-top: 20px;
        background-color: #28a745;
        color: #fff;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #218838;
        }
      }
    }
  }
}
