@import '../../../styles/themes/light.scss';
@import '../../../styles/themes/mixins.scss';

.whatsapp-button {
  @include display-flex;
  background-color: $color-success;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.25rem 0.75rem;
  transition: transform 0.3s;
  position: fixed;
  border-radius: 20px;
  bottom: 1.25rem;
  right: 1.2rem;
  width: 3.5rem;
  height: 3.5rem;
  z-index: 999;
  &:hover {
    transform: scale(1.1);
  }
  &::before,
  &::after {
    background-color: rgba(37, 211, 102, 0.5);
    position: absolute;
    animation: wave-animation 2s infinite ease-out;
    content: '';
    transform: scale(1);
    border-radius: 20px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }
  &::after {
    animation-delay: 1s;
  }
  .icon {
    color: $color-snow;
    width: 11rem;
    height: 11rem;
    padding: 0.5rem;
  }
}

@keyframes wave-animation {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
